import {
  Button,
  CalenderIcon,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  SharedIcon,
  Stack,
  Typography,
  useTheme,
} from "convertupleads-theme";
import React, {useEffect, useState} from "react";
import { IAnalyticsCampaign } from "./Analytics";
import AnalyticsList from "./AnalyticsList";
import ChartModal from "./ChartModal";
import AnalyticsGraph from "./AnalyticsGraph";
import { useDispatch } from "react-redux";
import {getCampaignEventAnalytics} from "../../../../../state/features/emailCampaign/emailCampaignSlice.ts";
import emailCampaignService from "../../../../../services/emailCampaign.service.ts";
import AnalyticsRightSideSkeleton from "./AnalyticsRightSideSkeleton.tsx";

interface Props {
  campaignUid: string;
  campaign: IAnalyticsCampaign;
}

const filters = ["ALL", "Today", "Yesterday", "Last 7 Days", "This Month", "Last Month", "Last 6 Months"];

const AnalyticsRightSide: React.FC<Props> = ({
  campaign,
  campaignUid,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [selectValue, setSelectValue] = useState("All Time");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<string>('ALL');//MONTH/DAY
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [emailStatus, setEmailStatus] = useState([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCampaignEventAnalytics({
      campaignUid: campaignUid,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      filterType: filterType
    }));

    (async ()=>{
      await getCampaignEventEmailAnalytics({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        filterType: filterType
      });
    })();
  }, []);

  const getCampaignEventEmailAnalytics = async ({ startDate, endDate, filterType } : { startDate: string; endDate: string; filterType: string; }) => {
      try{
        setLoadingData(true);
        const resp = await emailCampaignService.getCampaignEventEmailAnalytics({
          campaignUid: campaignUid,
          startDate: startDate,
          endDate: endDate,
          filterType: filterType
        });

        if (resp?.success && resp?.data){
          setEmailStatus(resp?.data);
        }
      }catch (err){
        console.log(err);
      }finally {
        setLoadingData(false);
      }
  };

  const convertedStartDate = (window as any).globalTimezoneConversionToDifferentTimezone(
    campaign.createdAt,
    "UTC",
    "",
    "yyyy-MM-DD"
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatDate = (date: Date | null) => {
    if (date){
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }

    return '';
  };

  const handleSelectData = async (data: number) => {
    const currentDate = new Date();
    const date = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let tempFilterType = 'ALL';
    let tempStartDate = startDate;
    let tempEndDate = endDate;

    switch (data) {
      case 0:
        setSelectValue("All Time");
        tempFilterType = 'ALL';
        break;
      case 1:
        setSelectValue("Today");
        tempFilterType = 'DAY';
        tempStartDate = tempEndDate = new Date(currentYear, currentMonth, date);
        break;
      case 2:
        setSelectValue("Yesterday");
        tempFilterType = 'DAY';
        tempStartDate = tempEndDate = new Date(currentYear, currentMonth, date - 1);
        break;
      case 3:
        setSelectValue("Last 7 Days");
        tempFilterType = 'DAY';
        tempStartDate = new Date(currentYear, currentMonth, date - 6);
        tempEndDate = new Date(currentYear, currentMonth, date);
        break;
      case 4:
        setSelectValue("This Month");
        tempFilterType = 'DAY';
        tempStartDate = new Date(currentYear, currentMonth, 1);
        tempEndDate = new Date(currentYear, currentMonth, date);
        break;
      case 5:
        setSelectValue("Last Month");
        tempFilterType = 'DAY';
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear;
        tempStartDate = new Date(lastYear, lastMonth, 1);
        tempEndDate = new Date(currentYear, currentMonth, 0);
        break;
      case 6:
        setSelectValue("Last 6 Months");
        tempFilterType = 'DAY';
        tempStartDate = new Date(currentYear, currentMonth - 5, date);
        tempEndDate = new Date(currentYear, currentMonth, date);
        break;
      default:
        return;
    }
    setFilterType(tempFilterType);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    handleClose();
    dispatch(getCampaignEventAnalytics({
      campaignUid: campaignUid,
      startDate: formatDate(tempStartDate),
      endDate: formatDate(tempEndDate),
      filterType: tempFilterType
    }));

    await getCampaignEventEmailAnalytics({
      startDate: formatDate(tempStartDate),
      endDate: formatDate(tempEndDate),
      filterType: tempFilterType
    });
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (isLoadingData){
    return <AnalyticsRightSideSkeleton/>;
  }

  return (
    <Stack
      width={"100%"}
      // flex={7}
      mt={1.5}
      mr={1.5}
      mb={1.5}
      spacing={1.5}
      sx={{ background: theme.palette.common.white }}
      justifyContent={"space-between"}
    >
      <Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          spacing={1}
          p={2}
        >
          {campaign?.title && campaignUid ? (
            <Typography variant="body2" fontWeight={700}>
              Campaign Title: {campaign?.title}
            </Typography>
          ) : (
            <Typography variant="body2" fontWeight={700}>
              All Campaign
            </Typography>
          )}

          {campaignUid && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              spacing={1}
              p={2}
            >
              <Button
                variant={"outlined"}
                startIcon={<CalenderIcon />}
                onClick={handleClick}
                sx={{
                  width: "190px",
                  justifyContent: "start",
                  color: `${theme.palette.action.active}`,
                  border: `1px solid ${theme.palette.action.active}`,
                  "&:hover": {
                    border: `1px solid ${theme.palette.action.active}`,
                  },
                  "&:focus": {
                    background: `transparent`,
                  },
                }}
              >
                {selectValue}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List component={"div"} sx={{ width: "180px" }}>
                  {
                    filters.map((filter, index)=>(
                        <ListItem disablePadding>
                          <ListItemButton onClick={() => handleSelectData(index)}>
                            <ListItemText primary={filter} />
                          </ListItemButton>
                        </ListItem>
                    ))
                  }
                </List>
              </Popover>
              <Button
                startIcon={<SharedIcon />}
                variant={"contained"}
                size={"large"}
                onClick={handleOpenModal}
                sx={{
                  "&:focus": {
                    backgroundColor: "primary.main",
                  },
                }}
              >
                Share
              </Button>
            </Stack>
          )}
        </Stack>

        <Divider light />

        {/* <AnalyticsTable emailStatus={emailStatus} /> */}
          <AnalyticsList
              campaignUid={campaignUid}
              emailStatus={emailStatus}
              filterData={{
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
                filterType: filterType
              }}
          />
          <AnalyticsGraph legendPosition={"right"}/>
      </Stack>

      {campaignUid && startDate && (
        <Stack py={2} spacing={2}>
          <Divider light />

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            px={6}
            mt={"auto"}
          >
            <Stack direction={"row"} spacing={1}>
              <Typography variant={"body2"} color={"text.secondary"}>
                <b>Start Date:</b>
              </Typography>
              <Typography variant={"body2"} color={"text.secondary"}>
                { filterType === "ALL" ? convertedStartDate : formatDate(startDate)}
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <Typography variant={"body2"} color={"text.secondary"}>
                <b>Last Date: </b>
              </Typography>
              <Typography variant={"body2"} color={"text.secondary"}>
                {formatDate(endDate)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}

      <ChartModal
        campaignUid={campaignUid}
        open={openModal}
        handleClose={handleCloseModal}
      />
    </Stack>
  );
};

export default AnalyticsRightSide;
